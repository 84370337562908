@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Mulish', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .desktop-navigation {
        @apply hidden h-[69px] bg-white max-w-[1600px] mx-auto rounded-full md:flex justify-center;
    }

}