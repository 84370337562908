@font-face {
  font-family: 'satoshi';
  src: url('../src/assets/fonts/satoshi/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi';
  src: url('../src/assets/fonts/satoshi/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi';
  src: url('../src/assets/fonts/satoshi/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

html {
  ::-webkit-scrollbar {
    display: none;
  }
}

body {
  font-family: "Bricolage Grotesque", sans-serif;
}

.navbar {
  box-shadow: 0 5px 10px rgba(40, 35, 86, 0.08);
}

.line {
  border: 1px solid rgba(48, 188, 237, 0.1);
}

.get-started {
  box-shadow: 15px 30px 40px rgba(28, 24, 64, 0.07);
}

.hamburger-react {
  background: #fff;
  border-radius: 50%;
  z-index: 999;
  color: #CBCBCB;
}


@media (max-width: 800px) {
  .get-started {
    box-shadow: none;
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }

}

.swiper-pagination {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.swiper-pagination-bullet {
  height: 10.6px !important;
  width: 10.6px !important;
  background: #30bced !important;
  border-radius: 100px !important;
  transition: all 0.4s ease-out !important;
}

.swiper-pagination-bullet-active {
  background-color: #30bced !important;
  width: 13px !important;
  height: 13px !important;
}